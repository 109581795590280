<template>
  <div class="container-box">
    <account-header @onClickBack="$router.go(-1)"></account-header>
    <div class="payment-method">
      <div class="pay-select-top">
        <div>Use the payment method below to pay</div>
        <div class="pay-select-box">
          <div  @click="currentPage = 0"  class="binance-icon"   :class="{'active': currentPage ==0}">
            <img src="@/assets/binance.png" alt="Binance Logo" class="payment-method-logo"/>
            <div>Binance</div>
          </div>
          <div @click="currentPage = 1"  class="binance-icon"   :class="{'active': currentPage ==1}" >
            <img src="@/assets/tronlink.png" alt="Binance Logo" class="payment-method-logo"/>
            <div>TronLink</div>
          </div>
          <div @click="currentPage = 2"  class="binance-icon"   :class="{'active': currentPage ==2}" >
            <img src="@/assets/wallet.png" alt="Binance Logo" class="payment-method-logo"/>
            <div >Wallet</div>
          </div>
        </div>
        <span>Select Amount</span>
      </div>

      <!-- 页面 1 -->
      <BiAnPay v-if="currentPage ==0">
      </BiAnPay>

      <!-- 页面 2 -->
      <TronLinkPay v-if="currentPage ==1">
      </TronLinkPay>
      <TelegramWalletPay v-if="currentPage ==2">
      </TelegramWalletPay>


    </div>
  </div>
</template>

<script>
import AccountHeader from "@/components/AccountHeader.vue";
import BiAnPay from "@/components/BiAnPay.vue";
import TronLinkPay from "@/components/TronLinkPay.vue";
import TelegramWalletPay from "@/components/TelegramWalletPay.vue";

export default {
  components: {AccountHeader,BiAnPay,TronLinkPay,TelegramWalletPay},
  data() {
    return {
      currentPage: 0 // 默认显示页面 1
    };
  },
  created() {

  },
  methods: {}
};
</script>

<style scoped>
.container-box {
  position: fixed;
  background-color: #1a1a1a;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  color: #FFFFFF;
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
}


.payment-method {
  display: flex;
  flex-direction: column;

  overflow-y: auto;
}
.payment-method-logo2 {
  display: block;
  width: 120px;
  height: 120px;
  border-radius: 14px ;
}
.payment-method-logo {
  filter: brightness(0) invert(1); /* 将非透明部分变成白色 */
  display: block;
  width: 60px;
  height: 60px;
  margin-bottom: 10px;
  object-fit: contain;
}

.binance-icon {
  width: 150px;
  height: 120px;

  border-radius: 14px 14px 14px 14px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  background-color:gray;
  font-family: Open Sans, Open Sans;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  font-style: normal;
  text-transform: none;
  margin: 30px 30px 30px 0px;
}

/* 选中状态的样式 */
.binance-icon.active {
  background-color: #15BB4F; /* 选中的 div 背景色 */
  color: white; /* 选中的 div 字体颜色 */
  border-color: #15BB4F; /* 选中的 div 边框颜色 */
  /*border: #15BB4F 4px solid;*/
}

/* 悬浮效果 */
.binance-icon:hover {
  /*background-color: #f0f0f0;*/
}

.pay-select-box {
  display: flex;
}

.pay-select-top {
  padding: 30px;
}

.wallet-text {
  position: absolute;
margin-top: 80px;
}
</style>
