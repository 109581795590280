<template>
  <div class="container">
    <account-header  @onClickBack="$router.push('/')"></account-header>
    <form @submit.prevent="handleSubmit" class="form">
      <div class="input-group">
        <label for="password">Enter New Password</label>
        <input
            type="password"
            id="password"
            v-model="password"
            placeholder="Enter your new password"
            required
        />
      </div>
      <button type="submit" class="next-button">Next</button>
    </form>
  </div>
</template>

<script>
import request from "@/utils/request";
import {onMounted, ref} from 'vue';
import {useRouter,useRoute} from 'vue-router';
import { ElMessage } from 'element-plus';
import AccountHeader from "@/components/AccountHeader";
export default {
  name: 'ForgotPassword',
  components: {
    AccountHeader,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const password = ref('');
    const email = ref('');
    const code = ref('');

    onMounted(() => {
      email.value = route.query.email;
      code.value = route.query.code;
      if (!code.value) {
        router.push("/");
      }

    });
    const handleSubmit = async () => {
      if (!password.value) {
        alert('Please enter your new password');
        return;
      }
    /*  if (!code.value) {
        alert('Please enter the verifcation code');
        return;
      }*/
      try {
        const response = await request({
          url: "/api/update/password",
          method: "post",
          data: {
            email: email.value,
            password: password.value,
            // code: code.value
          },
        });
        console.log(response)
        if (response) {
          ElMessage.success('Password updated successfully. Redirecting to login page...');
          setTimeout(() => {
            router.push("/");
          }, 2000);
        }
      } catch (error) {
        console.error('Error sending code:', error);
      }
    };

    return {
      password,
      email,
      code,
      handleSubmit,
    };
  }
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  background-color: #000;
  color: #fff;
  width: 100vw;
  height: 100vh;
  padding: 20px;
  box-sizing: border-box;
}

.form {
  padding: 20px;
  border-radius: 10px;
  width: 100%;
}

.input-group {
  margin-bottom: 20px;
  text-align: left;
}

.input-group label {
  display: block;
  font-size: 24px;
  margin-bottom: 30px;
  margin-top: 40px;
}

.input-group input {
  width: 100%;
  height: 78px;
  padding: 10px;
  border: 1px solid #444;
  border-radius: 15px;
  background-color: #282828;
  color: #fff;
  margin-bottom: 5px;
  box-sizing: border-box;
  font-size: 24px;
}

.small {
  font-size: 20px;
  color: #aaa;
  line-height: 30px;
  margin-top: 5px;
}

.verification-code {
  display: flex;
  align-items: center;
}

.verification-code input {
  flex: 1;
  margin-right: 10px;
}

.verification-code button {
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  background-color: #15BB4F;
  color: #fff;
  cursor: pointer;
}

.verification-code button:hover {
  background-color: #007e33;
}

.next-button {
  width: 100%;
  height: 80px;
  padding: 15px;
  border: none;
  background-color: #15BB4F;
  color: #fff;
  cursor: pointer;
  border-radius: 10px;
  margin-top: 30px;
  font-size: 40px;
}

.next-button:hover {
  background-color: #007e33;
}

/*@media (max-width: 480px) {
  .form {
    padding: 10px;
  }

  .verification-code button {
    padding: 8px 10px;
  }

  .next-button {
    padding: 10px;
    font-size: 1em;
  }
}*/
</style>
