<template>
  <div>
    <img  src="@/assets/float_button.png" alt="Profile Icon"
          v-show="isVisible"
          :style="buttonStyle"
          class="floating-button"
          @mousedown="startDrag"
          @touchstart="startDrag"
          @click="toggleMenu"/>
<!--    <button v-show="isVisible"
            :style="buttonStyle"
            class="floating-button"
            @mousedown="startDrag"
            @touchstart="startDrag"
            @click="toggleMenu">
      +
    </button>-->
    <UserInfoPP :isVisible="showMenu" :closeable="true" @update:isVisible="onClickBack" >
    </UserInfoPP>

  </div>
</template>

<script>
import UserInfoPP from "@/components/UserInfoPP";


export default {
  name: 'FloatingButton',
  components: { UserInfoPP },
  data() {
    return {
      isVisible: true,
      isDragging: false,
      initialY: 0,
      initialX: 0,
      buttonPosition: {top: window.innerHeight / 4 - 25, left: 0},
      showMenu: false,

    };
  },

  computed: {
    buttonStyle() {
      return {
        top: `${this.buttonPosition.top}px`,
        left: `${this.buttonPosition.left}px`,
        zIndex: 1000 // 确保悬浮按钮的层级最高
      };
    },
    getUserInfo() {
      const userStr = localStorage.getItem("user");
      console.log('====' + userStr)
      if (userStr) {
        const user = JSON.parse(userStr);
        return user;
      } else {
        return null
      }
    },
  },
  methods: {
    show() {
      this.isVisible = true;
    },
    hide() {
      this.isVisible = false;
    },
    startDrag(event) {
      this.isDragging = true;
      this.initialY = event.clientY || event.touches[0].clientY;
      this.initialX = event.clientX || event.touches[0].clientX;
      document.addEventListener('mousemove', this.drag);
      document.addEventListener('touchmove', this.drag);
      document.addEventListener('mouseup', this.stopDrag);
      document.addEventListener('touchend', this.stopDrag);
    },
    drag(event) {
      if (!this.isDragging) return;
      const clientY = event.clientY || event.touches[0].clientY;
      const clientX = event.clientX || event.touches[0].clientX;
      const newTop = Math.min(window.innerHeight - 50, Math.max(0, clientY - this.initialY + this.buttonPosition.top));
      const newLeft = Math.min(window.innerWidth - 50, Math.max(0, clientX - this.initialX + this.buttonPosition.left));
      this.buttonPosition = {top: newTop, left: newLeft};
      this.initialY = clientY;
      this.initialX = clientX;
    },
    stopDrag() {
      this.isDragging = false;
      document.removeEventListener('mousemove', this.drag);
      document.removeEventListener('touchmove', this.drag);
      document.removeEventListener('mouseup', this.stopDrag);
      document.removeEventListener('touchend', this.stopDrag);
      // 吸附到屏幕左侧
      this.buttonPosition.left = 0;
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    closeMenu() {
      this.showMenu = false;
    },
    selectOption(option) {
      console.log('Selected option:', option);
      this.showMenu = false;
    },
    onClickBack() {
      this.showMenu = false;
    }
  }
};
</script>

<style scoped>
.floating-button {
  position: absolute;
  transform: translateY(-50%);
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #007bff;
  /*background: url('@/assets/avatar.png') no-repeat center center fixed;*/
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  user-select: none;
  touch-action: none;

}


.menu li {
  padding: 10px 20px;
  cursor: pointer;
}

.menu li:hover {
  background-color: #f0f0f0;
}





</style>
