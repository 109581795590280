// src/router/index.js
import {createRouter, createWebHistory} from "vue-router";
// import { isAuthenticated } from "@/js/auth";
import HomePage from "../views/HomePage.vue";
import LoginPage from "../views/login/EmailLoginPage.vue";
import TelegramLogin from "../views/login/TelegramLogin.vue";
import GamePlayPage from "../views/GamePlayPage.vue";
import XieYiPage from "../views/XieYiPage.vue";
import ForgotPasswordPage from "../views/ForgotPasswordPage.vue";
import UpdatePwdPage from "../views/UpdatePwdPage.vue";
import PayPage from "../views/order/PayPage.vue";
import OrderListPage from "../views/OrderListPage.vue";
import GameListPage from "../views/GameListPage.vue";
import QRcodePay from "../views/order/QRcodePay.vue";
// import userService from "@/utils/userService";
// import userService from "@/utils/userService";


const routes = [
    {
        path: "/homePage",
        name: "HomePage",
        component: HomePage,
        meta: {
            keepAlive: true,
        },
        // meta: { requiresAuth: true }, // 标记需要登录才能访问的页面
    },
    {
        path: '/',
        redirect: '/gameList'
    },
    {
        path: '/index',
        redirect: '/gameList',

    },
    {
        path: "/login",
        name: "LoginPage",
        component: LoginPage,
    },
    {
        // path: '/gamePlay/:id',
        path: '/gamePlay',
        name: "GamePlayPage",
        component: GamePlayPage,
        meta: { requiresAuth: true }, // 需要登录的标记
    },
    {
        path: "/xieYiPage",
        name: "XieYiPage",
        component: XieYiPage,
    },
    {
        path: "/forgotPasswordPage",
        name: "ForgotPasswordPage",
        component: ForgotPasswordPage,
    },
    {
        path: "/updatePwdPage",
        name: "UpdatePwdPage",
        component: UpdatePwdPage,
    },
     {
        path: "/payPage",
        name: "PayPage",
        component: PayPage,
         meta: { requiresAuth: true }, // 需要登录的标记
    },
    {
        path: "/qrCodePay",
        name: "QRcodePay",
        component: QRcodePay,
        meta: { requiresAuth: true }, // 需要登录的标记
    },


    {
        path: "/orderList",
        name: "OrderList",
        component: OrderListPage,
        meta: { requiresAuth: true }, // 需要登录的标记
    },
    {
        path: "/gameList",
        name: "GameListPage",
        component: GameListPage,
        meta: { requiresAuth: true }, // 需要登录的标记
    },
    {
        path: "/telegramLogin",
        name: "TelegramLogin",
        component: TelegramLogin,
    },


];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

/*router.beforeEach((to, from, next) => {
   /!* if (to.path !== '/login') {
        localStorage.setItem('previousPath', from.fullPath);
    }*!/
    if (to.path !== '/login') {
        const previousPath = from.fullPath;
        const previousQuery = new URLSearchParams(from.query).toString();
        const previousPathWithQuery = previousQuery ? `${previousPath}?${previousQuery}` : previousPath;
        localStorage.setItem('previousPath', previousPathWithQuery);
    }
    next();
});*/


// 全局路由守卫
/*router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        next({
            path: '/telegramLogin',
            query: { ...to.query } // 保留当前查询参数
        });
     /!*   if (from.path.includes('login') || from.path.includes('telegramLogin')) {
            next();
            return;
        }
        // 检查用户是否已登录
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has('os')&&urlParams.has('token')&&urlParams.get('os')=="telegram") {
            next({
                path: '/telegramLogin',
                query: { ...to.query } // 保留当前查询参数
            });
        } else {
            // 用户已登录，允许访问
            next();
        }*!/
    } else {
        // 不需要身份验证的页面，直接访问
        next();
    }
    // 检查目标路由是否需要身份验证
  /!*  if (to.matched.some(record => record.meta.requiresAuth)) {
        // 检查用户是否已登录
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has('token')&&urlParams.has('os')&&urlParams.get('os')=="telegram") {
            let access_token = urlParams.get("token");
            userService.tokenLogin(access_token)
            let platform_os = urlParams.get("os");
            userService.setPlatform_os(platform_os)
        }
        const isAuthenticated = userService.isLogin(); // 简单的登录检查
        if (!isAuthenticated) {
            // 用户未登录，重定向到登录页面
            // next({ path: '/login', query: { redirect: to.fullPath } });
            next({
                path: '/login',
                query: { ...to.query } // 保留当前查询参数
            });
        } else {
            // 用户已登录，允许访问
            next();
        }
    } else {
        // 不需要身份验证的页面，直接访问
        next();
    }*!/
});*/
export default router;
