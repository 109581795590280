<template>
  <div class="header">
    <div class="header-left">
      <div class="logo" @click="onClickBack">
        <img src="@/assets/back.png"/>
      </div>
      <div class="title-text"><slot name="title"></slot></div>
    </div>
    <div class="profile-icon" v-if="false">
      <img v-if="!isLogin" src="@/assets/avatar.png" alt="Profile Icon" @click="toLogin"/>
      <div v-else-if="isAvater" class="avatar" @click="toggleMenu">{{ this.fname }}</div>
      <div v-if="showMenu" class="menu">
<!--        <div class="menu-item" @click="toUser">用户详情</div>-->
        <div class="menu-item" @click="toLogin">Logout</div>
      </div>
    </div>
  </div>
  <div class="block"></div>
</template>
<script>
export default {
  name: "AccountHeader",
  components: {},
  props: {
    isAvater: {
      default: true,
      type: Boolean,
    },
  },
  data() {
    return {
      showMenu: false,
    };
  },
  computed: {
    isLogin() {
      return !!localStorage.getItem("token");
    },
    fname() {
    /*  const user = localStorage.getItem("user");
      const google = user && JSON.parse(user).google;
      const regex = /[A-Za-z]/;
      let match;
      // 使用正则表达式在字符串中查找
      while ((match = regex.exec(google))) {
        // 返回找到的第一个英文字母
        const fname = match[0] && match[0].toUpperCase();
        console.log(fname);
        return fname;
      }*/
      return "U";
    },
  },
  created() {
  },
  methods: {
    toggleMenu() {
      // this.showMenu = !this.showMenu;
    },
    toUser() {
      //在这里弹出一个选项列表，退出，用户详情
      /*this.$router.push({
        name: "User",
      });*/
    },
    toLogin() {
      // this.$router.push("/login");
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      this.showMenu = false;
      this.$router.push("/login");
      // this.$router.push({path: '/login'});

    },
    onClickBack() {
      this.$emit('onClickBack');
      this.$router.push("/");
    },
  },
};
</script>
<style scoped>
.block {
  height: 70px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  background-color: #15BB4F;
  height: 70px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  z-index: 999;
}

.header-left {
  display: flex;
  flex: 1;
  align-items: center;
}

.logo {
  display: flex;
  align-items: center;
  width: 50px;
  height: 100%;
}

.logo img {
  width: 20px;
  object-fit: contain;
}

.header-text {
  margin-left: 15px;
  height: 64px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.header-text .title {
  /* margin-top: 7px;
  margin-bottom: 15px; */
  font-size: 27px;
  color: #ffffff;
}

.header-text .des {
  font-size: 21px;
  color: #ffffff;
}

.profile-icon {

  border-radius: 50%;

}

.profile-icon img {
  width: 40px;
  height: 40px;
  object-fit: contain;
  border-radius: 50%;
}

.profile-icon .avatar {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  background: #fff;
  font-weight: bold;
  font-size: 32px;
  color: #15BB4F;
  text-align: center;
}

.menu {
  position: absolute;
  top: 80px;
  right: 0;
  background: white;
  border: 1px solid #ccc;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.menu-item {
  padding: 10px 20px;
  cursor: pointer;
}

.menu-item:hover {
  background-color: #f0f0f0;
}

.title-text {
  font-family: PingFang SC;
  font-weight: bold;
  font-size: 30px;
  color: #FFFFFF;
  line-height: 10px;
}
</style>
