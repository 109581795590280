<template>

  <div v-if="isVisible" class="container-box">
    <div class="login-container">
      <img src="@/assets/logo.png" alt="Pepe" class="logo"/>
      <p class="intro">
        For just <span class="highlight">$1</span>, you can earn <span class="highlight">100</span> game coins<br>
        for a chance to win millions!
      </p>
      <form @submit.prevent="handleSubmit" class="from-box">
        <div class="input-group">
          <label for="email">E-mail</label>
          <input type="email" id="email" v-model="email" placeholder="Enter your Email" />
        </div>
        <div class="input-group">
          <label for="password">Password</label>
          <input type="password" v-model="password" placeholder="Enter Password" />
          <label>Enter a password of your choice (Between 5-16 characters)</label>
        </div>
        <button type="submit" class="btn">Register or Login</button>
        <div class="forgot-password" @click="onClickForgotPassword">
          Forgot your password?
        </div>
      </form>
    </div>
    <div class="empty-box"></div>
    <div class="terms-box">
     <div class="terms">
       <input  type="checkbox"  checked id="terms" v-model="termsAccepted" required/>
       <div for="terms">I am over 18 years of age and I accept
         YOMIGAME</div>
     </div>
      <div @click="onClickXieYi" class="xieyi-text">
        <a class="xieyi-text">General Terms And Conditions</a>
      </div>

    </div>
  </div>
</template>

<script>
import { ElMessage } from 'element-plus';
import userService from "@/utils/userService";
export default {
  data() {
    return {
      email: '',
      password: '',
      termsAccepted: true,
      isVisible: false,
    };
  },
  methods: {
    openModal() {
      this.isVisible = true;
    },
    closeModal() {
      this.isVisible = false;
    },
    handleSubmit() {
      if (this.email.trim().length === 0) {
        // alert('Please enter your email.');
        ElMessage({
          message: 'Please enter your email.',
          type: 'warning',
          offset:300,
        });
        return
      }
      let length = this.password.trim().length;
      if (length < 5||length>16) {
        // alert('Please Enter a password of your choice (Between 5-16 characters)');
        ElMessage({
          message: 'Please Enter a password of your choice (Between 5-16 characters)',
          type: 'warning',
          offset:300
        });
        return
      }
      if (this.termsAccepted) {
        // 处理表单提交逻辑
        this.login();
      } else {
        ElMessage({
          message: 'You must accept the terms and conditions.',
          type: 'warning',
          offset:300,


        });
        // alert('You must accept the terms and conditions.');
      }
    },

    login() {
      const urlParams = new URLSearchParams(window.location.search);
      let platform_os = '';
      if (urlParams.has('os')) {platform_os=urlParams.get("os");}
      userService.setPlatform_os(platform_os)
      userService.login(this.email, this.password,(loginError, loginResponse) => {
        if (loginError) {
          console.log("==========login error",loginError)
          // 在这里处理登录失败的情况，例如显示错误信息
          return;
        }
        console.log("==========login ",loginResponse)
        userService.updateUserInfo((error, response) => {
          if (error) {
            console.log("==========updateUserInfo error",error)
            return;
          }
          console.log("==========updateUserInfo response",response)
          this.$emit('login', {
            token: loginResponse.token,
            // gameUrl: data.gameUrl,
          });
          this.closeModal()
        });
      });
    },

    onClickXieYi() {
      this.$router.push(`/xieYiPage`);
    },
    onClickForgotPassword() {
      this.$router.push(`/forgotPasswordPage`);
    },

  },
};
</script>

<style scoped>


.container-box {

  display: flex;
  flex-direction: column;
  background-color: #4A7B29; /* 背景颜色 */
  /*background: rgba(0, 0, 0, 0.5);*/

  overflow-y: auto; /* 使内容超过屏幕时可以滚动 */

}

.login-container {
  align-items: center;
  text-align: center;
  color: white;
  padding: 35px;
}

.empty-box {
  flex: 1;
}

.terms-box {
  background: #2A590B;
  border-radius: 25px 25px 0px 0px;
  width: 100%;
  color: white;
  align-items: center;
  padding-top: 35px;
  padding-bottom: 35px;
  display: flex;
  flex-direction: column;
}


.logo {
  width: 480px;

}

.intro {
  font-size: 23px;
  margin-bottom: 20px;
  text-align: left;
  line-height: 36px;
}

.highlight {
  color: #4CAF50; /* 高亮颜色 */
  font-size: 28px;
}

.input-group {
  margin-bottom: 15px;
  text-align: left;
}

.input-group label {
  display: block;
  margin-bottom: 5px;
  font-size: 24px;
}

.input-group input {
  width: 100%;
  height: 70px;
  padding: 8px 21px;
  box-sizing: border-box;
  color: black;
  border-radius: 35px;
  border: 2px solid #00BFFF; /* 边框颜色 */
  font-size: 23px;
  margin: 10px 0px;

}

.btn {
  background-color: #00A5A4;
  width: 100%;
  height: 80px;
  color: white;
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 30px;
  margin-top: 10px;
  cursor: pointer;
  border-radius: 40px;
  margin-top: 40px;
}

.forgot-password {
  display: block;
  margin-top: 20px;
  font-family: PingFang SC;
  font-weight: 500;
  font-size: 20px;
  color: #00F1BB;
  line-height: 30px;
}

.terms {
  display: flex;
  font-size: 19px;
  align-items: center;
  margin-left: 30px;
}

.terms label {
  display: flex;
  align-items: center;
}

.terms input[type="checkbox"] {
  margin-right: 30px;
  width: 30px;
  height: 30px;
}

.from-box {
  background: #2A590B;
  border-radius: 15px;
  padding: 35px 29px;
  font-size: 28px;
}

.xieyi-text {
  font-size: 28px;
  color: white;
  text-decoration-line: underline;
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 10px;
}


</style>
