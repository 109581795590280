<template>
  <div v-if="isVisible" class="modal-overlay" @click="handleOverlayClick">
    <!--    <div class="modal-content" @click.stop>

        </div>-->
    <div v-if="userInfo" class="modal-content">
      <div class="item-top">
        <img src="@/assets/float_button.png" alt="Profile Icon"/>
        <div> {{ userInfo.user_name }}</div>
        <div class="text-logout" @click="logout"> [ Logout ]</div>
      </div>
      <div class="line-fengexian"></div>
      <div class="text-item"> Total recharge amount: {{ userInfo.recharge }}</div>
      <div class="text-item"> Total game coins: {{ userInfo.balance }}</div>
      <div class="menu-button-box">
        <div class="recharge-box">
          <button @click="orderDetails">Order Details</button>
        </div>
        <div class="recharge-box">
          <button @click="recharge">RECHARGE</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import userService from "@/utils/userService";

export default {
  props: {
    closeable: {
      type: Boolean,
      default: true,
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      userInfo: null,
    };
  },
  watch: {
    isVisible(val) {
      if (val) {
        console.log("=====isVisible=1")
        userService.updateUserInfo((error, response) => {
          this.userInfo = response;
        })
      }
    },
  },
  methods: {
    openModal() {
      this.$emit('update:isVisible', true);
    },
    closeModal() {
      if (this.closeable) {
        this.$emit('update:isVisible', false);
      }
    },
    handleOverlayClick() {
      if (this.closeable) {
        this.closeModal();
      }
    },

    logout() {
      userService.logout();
    },
    recharge() {
      this.$router.push(`/payPage`);
    },
    orderDetails() {
      this.$router.push(`/orderList`);
    }
  },
  mounted() {
    userService.updateUserInfo((error, response) => {
      this.userInfo = response;
    })
  },
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  border-radius: 10px;
  padding: 50px 30px;
  margin: 0px 40px;
  width: 100%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  color: white;
  background: rgba(0, 0, 0, 0.82);
  display: flex;
  flex-direction: column;
  align-items: center;
}


.content-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;

}

.modal-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  background: white;
  padding: 20px 40px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  color: black;
  font-size: 23px;

}

.menu {
  list-style-type: none;
  margin: 0;
  padding: 0;
}


.line-fengexian {
  background: black;
  width: 100%;
  height: 1px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.item-top {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.text-logout {
  color: #5f5f5f;
  font-size: 25px;
  margin-left: 15px;
}

.recharge-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
}


.text-item {
  margin-top: 10px;
  margin-bottom: 10px;
}

.menu-button-box {
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
}


.item-top img {
  width: 60px;
  height: 60px;
  object-fit: contain;
  border-radius: 50%;
  margin-right: 10px;
}


.recharge-box button {
  border: none;
  border-radius: 10px;
  background-color: #15BB4F;
  color: #fff;
  cursor: pointer;
  font-size: 25px;
  padding: 15px 30px;

}

</style>
