// src/main.js
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
// import { createGtag } from 'vue-gtag';
import VueGtag from "vue-gtag";
// import "amfe-flexible";
import ElementPlus from 'element-plus';
import { Buffer } from 'buffer';

window.Buffer = Buffer;
// vant
import { List, Button, Tab, Tabs } from "vant";
import "vant/lib/index.css";
import 'element-plus/dist/index.css'
import "./assets/style/main.css"
// 引入英文语言包
import { Locale } from "vant";
import enUS from "vant/es/locale/lang/en-US";
// 图片懒加载
import VueLazyload from "vue-lazyload";
import userService from "@/utils/userService";
const loadimage = require("@/assets/default.png");

Locale.use("en-US", enUS);
const app = createApp(App);
app.use(router);
app.use(VueGtag, {
  config: {
    id: "G-D1GKJ58GPR",  // 替换为你的 Google Analytics ID
    params: {
      os_isTelegram: userService.isTG()
    }
  }
});
app.mount("#app");
app.use(List);
app.use(Button);
app.use(Tab);
app.use(Tabs);
app.use(ElementPlus)
app.use(VueLazyload, {
  // 设置默认图片
  loading: loadimage,
  // 设置错误图片
  error: loadimage,
});
