<template>
  <div class="container-box">
    <iframe ref="myIframe" marginwidth="0" marginheight="0" frameborder="0" scrolling="no"
            allowtransparency="true" class="iframe-box"></iframe>
    <FloatingButton ref="floatingButton" class="floating-btn"/>
  </div>
</template>

<script setup>
import {ref, onBeforeMount, onMounted, onBeforeUnmount} from 'vue';
import FloatingButton from '@/components/FloatingButton.vue';
import Request from "@/utils/request";
import userService from "@/utils/userService";
import {useRouter,useRoute} from "vue-router";

const myIframe = ref(null);
const floatingButton = ref(null);
const router = useRouter();
const route = useRoute();
const gameId = ref(null);
/*const sendMessageToIframe = () => {
  if (myIframe.value) {
    const iframe = myIframe.value.contentWindow;
    iframe.postMessage('Hello from parent', '*');
  }
};*/

const receiveMessageFromIframe = (event) => {

  // console.log('onevent origin========',event.origin);
  // if (event.origin !== 'http://example.com') return; // 检查消息的来源
  if (!event.origin.includes("yomi")) return; // 检查消息的来源
  if (event.data == 'insufficient') {
    // router.push({ path: '/payPage' });
    router.push({ path: '/payPage' });
  }else if (event.data == "endGame") {
    // alert('Under Development');
    router.push({ path: '/gameList' });
  }
};

/*const showLoginPP = () => {
  loginPP.value.openModal();
};*/

//登录完成的回调
/*const handleOnLogin = (loginInfo) => {
  console.log(loginInfo);
  getGameUrl()
  // getUserInfo()
};*/


/*const getUserInfo = () => {
  Request({
    url: '/api/userinfo',
    method: 'post',
    data: {}
  }).then(data => {
    localStorage.setItem("user",  JSON.stringify(data));
  })
};*/

const getGameUrl = () => {
  Request({
    url: '/api/game/get/id',
    method: 'post',
    data: {game_id:parseInt(gameId.value)}
  }).then(data => {
    console.log(data)
    if (myIframe.value) {
      myIframe.value.src = data.game_url;
    }
    // floatingButton.value.show()
  })
};

onBeforeMount(() => {
  console.log('onBeforeMount========');

});
onMounted(() => {
  // gameId.value = route.params.id ? route.params.id : '1';
  gameId.value = route.query.id ? route.query.id : '1';
  console.log('onMounted========');
  window.addEventListener('message', receiveMessageFromIframe);
  userService.updateUserInfo()
  getGameUrl()
 /* const access_token = localStorage.getItem("token");
  if (access_token) {
    handleOnLogin(null);
  }else {
    showLoginPP()
  }*/
});

onBeforeUnmount(() => {
  console.log('onBeforeUnmount========');
  window.removeEventListener('message', receiveMessageFromIframe);
});
</script>


<style scoped>
.container-box {

  width: 100%;
  height: 100%;
}

.iframe-box {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  z-index: 1;
}

.overlay {
  width: 100%;
  height: 100%;
  position: relative;
  top: 0;
  left: 0;
  z-index: 2;
  pointer-events: auto; /* Ensure it can receive pointer events */
}

.floating-btn {
  z-index: 3;
}
</style>

