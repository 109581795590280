import axios from "axios";
import {showToast, showDialog, closeDialog} from "vant";
import userService from "@/utils/userService";

let withoutMsg = false; //取消报错提醒
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL, // url = base url + request url
    timeout: 15000, // request timeout
});

// request interceptor
service.interceptors.request.use(
    (config) => {
      /*  const urlParams = new URLSearchParams(window.location.search);
        let access_token = urlParams.get("token");
        if (!access_token) {
            //从地址中获取token
            access_token = localStorage.getItem("token");
        }*/
        let  access_token = localStorage.getItem("token");
        if (access_token) {
            config.headers = {
                "x-token": access_token,
                ...config.headers,
            };
        }

        if (config.method === 'post') {
            config.data = {
                ...config.data,
                os: userService.isTG()?"telegram":"h5" // 将"设备"替换为具体的设备信息
            };
        }else {
            // 添加公共请求参数
            if (!config.params) {
                config.params = {};
            }
            config.params.os = userService.isTG()?"telegram":"h5"; // 这里的"设备"可以替换为实际的设备信息
        }
        withoutMsg = config.withoutMsg;
        showDialog({message: 'loading', showConfirmButton: false});
        return config;
    },
    (error) => {
        // do something with request error
        closeDialog();
        console.log(error); // for debug
        return Promise.reject(error);
    }
);

// response interceptor
service.interceptors.response.use(
    (response) => {
        const res = response.data;
        closeDialog();
        if (!res) {
            showToast({
                message: "http error",
                forbidClick: true,
            });
            return Promise.reject(response);
        }
        if (withoutMsg) {
            return res;
        } else if (res.code === 9999) {
            // showToast({
            //   message: res.message || "Error",
            //   forbidClick: true,
            //   onClose: () => {
            userService.reLogin()
            // window.location = `/login?redirect=${encodeURIComponent(window.location.pathname)}`;
            //   },
            // });
            return Promise.reject(res);
        } else if (res.code !== 1000) {
            console.log(res);
            showToast({
                message: res.message || "Error",
                forbidClick: true,
            });
            return Promise.reject(res);
        } else {
            return res.data;
        }
    },
    (error) => {
        console.log("err：" + error); // for debug
        closeDialog();
        showToast({
            message: error.message,
            forbidClick: true,
        });
        return Promise.reject(error);
    }
);

// JSON axios

export default service;
