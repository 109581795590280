<template>
  <div >
    <account-header @onClickBack="$router.go(-1)"></account-header>
    <div class="payment-form">
      <div class="payment-title">This is your personal account</div>
      <div class="payment-des">Deposit to this address and the amount will be credited</div>
      <img class="img-qrcode" :src="data.qrcode">
      <div class="payment-walletAddress">{{data.walletAddress}}</div>
      <div class="payment-copy" @click="copyCode">Click to copy</div>
      <div class="notify-us">
        If you have already recharged, please click here to let us know.
        <div class="notify-us-click" @click="notifyUs()">
          Click to notify us
        </div>
      </div>
      <div class="notice-box" v-if="data.notice&&data.notice">
        <div class="notice-title">
          <img src="@/assets/wow.png" alt="Good News" class="title-img"/>
          NOTES
        </div>
        <div class="notice-text" >
          {{ data.notice }}
        </div>
      </div>
    </div>
    <RemindPP :isVisible="isShowErrorPP" :closeable="true" @update:isVisible="onClickBack">
      <template v-slot:body>
        {{ initErrorMsg }}
      </template>
    </RemindPP>
  </div>
</template>

<script>
// import Request from "@/utils/request";
import RemindPP from "@/components/RemindPP.vue";
import { useClipboard } from '@vueuse/core'
import request from "@/utils/request";
import {showToast} from "vant";
import {event} from "vue-gtag";
import AccountHeader from "@/components/AccountHeader";

export default {
  components: {RemindPP,AccountHeader},
  data() {
    return {
      isShowErrorPP: false,
      initErrorMsg: "",
      data: {

        notice:
            '1.Please select USDT-TRC20 as the payment method.\n' +
            '2.The deposit will arrive in 10 minutes.\n' +
            '3.The more you recharge, the more game coins you will receive as gifts.\n' +
            '4.lf you have any questions, Please contact customer services.',
        qrcode: '',
        walletAddress: '',
        orderNumber: '',
      }
    };
  },
  created() {
    console.log("===="+this.$route.query);
    if (this.$route.query){
      this.data.qrcode = this.$route.query.qrcode
      this.data.walletAddress = this.$route.query.walletAddress
      this.data.orderNumber = this.$route.query.orderNumber
    }

    // this.orderInit();
  },
  methods: {
    validateInput(event) {
      const value = event.target.value;
      this.customAmount = value.replace(/[^\d]/g, '');
      this.selectedAmount = 0;
    },
    selectAmount(amount) {
      this.selectedAmount = amount;
      this.customAmount = amount;
      this.showCustomAmount = false;
    },
    copyCode() {
      if (window.clipboardData) {
        window.clipboardData.setData('text', this.data.walletAddress);
      }else {
        const {copy } = useClipboard({ legacy: true })
        copy( this.data.walletAddress)
      }
      this.report()
      event('click_copy_qrcode', { method: 'Click' })
      console.log('Copy Successed');
    },
    async report() {
      try {
        const response = await request({
          url: "/api/order/copy",
          method: "post",
          data: {
            orderNumber: this.data.orderNumber,
          },
        });
        console.log(response)
        alert('Copy Success');
      } catch (error) {
        console.log(error)
      }
    },
    async notifyUs()  {
      try {
        const response = await request({
          url: "/api/order/notify",
          method: "post",
          data: {
            orderNumber: this.data.orderNumber,
          },
        });
        console.log(response)
      /*  showToast({
          message: response,
          forbidClick: true,
        });*/
      } catch (error) {
        showToast({
          message: error.message,
          forbidClick: true,
        });
      }
    },

 /*   orderInit() {
      Request({
        url: '/telegram/order/init',
        method: 'post',
        data: {type: 0}
      }).then(data => {
        this.data.qrcode = data.qrcode
        this.data.walletAddress = data.walletAddress
        // this.data.notice= data.notice
      }).catch(error => {
        this.isShowErrorPP = true;
        this.initErrorMsg = error.message;
      });
    },*/
    onClickBack() {
      this.isShowErrorPP = false;
    }
  }
};
</script>

<style scoped>


.payment-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: #000000;
  color: #D7D7D7;
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
  overflow-y: auto;
}


.notice-box {
  background: #282828;
  margin-bottom: 50px;
  padding: 30px;
  color: #D7D7D7;
  font-size: 25px;
}

.notice-title {
  display: flex;
  width: 100%;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-size: 30px;
  margin-bottom: 10px;
}

.title-img {
  height: 40px;
  margin-right: 10px;
}

.img-qrcode {
  width: 390px;
  height: 390px;
  margin-top: 30px;
}

.payment-title {

  font-family: PingFang SC;
  font-weight: bold;
  font-size: 26px;
  color: #FFFFFF;
  line-height: 30px;
  text-stroke: 1px #333333;
  -webkit-text-stroke: 1px #333333;
  margin-top: 40px;
}

.payment-des {
  margin-top: 18px;
}

.payment-walletAddress {

  font-family: PingFang SC;
  font-weight: 500;
  font-size: 22px;
  color: #FFFFFF;

  margin-top: 15px;
}

.payment-copy {
  width: 268px;
  height: 60px;
  background: #15BB4F;
  border-radius: 30px;
  font-family: PingFang SC;
  font-weight: bold;
  font-size: 26px;
  color: #FFFFFF;
  line-height: 30px;
  text-align: center;
  line-height: 60px;
  margin-top: 20px;
  margin-bottom: 50px;
}

.notice-text {
  white-space: pre-wrap;
  line-height: 35px;
}

.notify-us {

  color: #FFFFFF;
  margin-top: 20px;
  font-size: 21px;
}
.notify-us-click {
  font-weight: bold;
  text-decoration: underline;
  width: 100%;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;

}
</style>
