import Request from "@/utils/request";
import router from "@/router"; // 引入通用请求工具类

const userService = {
    // 用户登录


    // 用户登录，增加 callback 参数
    login(email, password, callback) {
        Request({
            url: '/api/login',
            method: 'post',
            data: {email: email, password: password}
        }).then(data => {
            console.log("==========login data", data);
            localStorage.setItem("token", data.token);
            callback(null, data);
        }).catch(error => {
            console.log("==========", error);
            if (callback) callback(error);
        });
    },

    tokenLogin(token, callback) {
        /*  const urlParams = new URLSearchParams(window.location.search);
          if (urlParams.has('token')) {
              let access_token = urlParams.get("token");
              localStorage.setItem("token", access_token);
          }*/
        // localStorage.setItem("token", token);
        Request({
            url: '/api/login/token',
            method: 'post',
            data: {token: token}
        }).then(data => {
            console.log("==========login data", data);
            localStorage.setItem("token", data.token);
            callback(null, data);
        }).catch(error => {
            console.log("==========", error);
            if (callback) callback(error);
        });
    },

    uidLogin(uId, uName, callback) {
        Request({
            url: '/telegram/login/openid',
            method: 'post',
            data: {openid: uId.toString(), email: uName}
        }).then(data => {
            console.log("==========login data", data);
            localStorage.setItem("token", data.token);
            localStorage.setItem("user", JSON.stringify(data));
            callback(null, data);
        }).catch(error => {
            console.log("==========", error);
            if (callback) callback(error);
        });
    },


    setToken(token) {
        localStorage.setItem("token", token);
    },
    isLogin() {
        if (localStorage.getItem("token")) {
            return true
        }
        return false
    },

    getPlatform_os() {
        return localStorage.getItem("platform_os") || "";
    },
    isTG() {  //是否为telegram 小程序
        if (window.Telegram.WebApp && window.Telegram.WebApp.initDataUnsafe && window.Telegram.WebApp.initDataUnsafe.user) {
            return true
        } else {
            return false
        }
        /*  if (window.Telegram && window.Telegram.WebApp && window.Telegram.WebApp.initData) {
              return true
          } else {
              return false
          }*/
    },

    setPlatform_os(os) {
        if (os) {
            localStorage.setItem("platform_os", os);
        }
    },

    // 用户退出登录
    logout() {
        // return service.post("/logout");
        localStorage.removeItem("token");
        // window.location = "/login";
        // window.location.reload();
        // localStorage.removeItem("token");
        // const currentQuery = window.location.search;
        // window.location = "/login";
        router.push("/login");
        // window.location = `/login${currentQuery}`;

    },
    reLogin() {
        localStorage.removeItem("token");
        // const currentQuery = window.location.search;
        if (this.isTG()) {
            // window.location = "/telegramLogin";
            router.push("/telegramLogin");
            // window.location = `/telegramLogin${currentQuery}`;
        } else {
            // window.location = "/login";
            router.push("/login");
            // window.location = `/login${currentQuery}`;
        }
    },


    // 获取当前登录用户信息
    getUserInfo() {
        const userStr = localStorage.getItem("user");
        console.log('====' + userStr)
        if (userStr) {
            const user = JSON.parse(userStr);
            return user;
        } else {
            return null
        }
        // return service.get("/user/info");
    },
    // 更新用户信息
    updateUserInfo(callback) {

        Request({
            url: '/api/userinfo',
            method: 'post',
            data: {}
        }).then(data => {
            localStorage.setItem("user", JSON.stringify(data));
            if (callback) callback(null, data);
        }).catch(error => {
            console.log("==========", error);
            if (callback) callback(error);
        });
    },


    getPoPup(callback) {

        Request({
            url: '/api/popup/init',
            method: 'post',
            data: {}
        }).then(data => {
            if (callback) callback(null, data);
        }).catch(error => {
            console.log("==========", error);
            if (callback) callback(error);
        });
    }
};

export default userService;
