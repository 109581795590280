<template>
  <div class="container-box">
    <LoginPP ref="loginPP" @login="handleOnLogin"></LoginPP>
  </div>
</template>

<script >

import LoginPP from "@/components/LoginPP";

export default {
  components: { LoginPP},
  data() {
    return {
    };
  },
  mounted() {
    this.showLoginPP();
  },
  methods: {
    handleOnLogin(loginInfo) {
      console.log(loginInfo)
      // this.$router.go(-1);
      const previousPath = localStorage.getItem('previousPath') || '/';
      this.$router.replace({ path: previousPath }).then(() => {
        // window.location.reload();
      });
    },
    showLoginPP() {
      this.$refs.loginPP.openModal()
    }
  }
};
</script>


<style scoped>
.container-box {
  position: fixed;
  width: 100%;
  height: 100%;
}
</style>

