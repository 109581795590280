<template>
  <div class="container">
    <div class="img-bg"></div>
    <div v-if="userInfo" class="game-coins" @click="recharge">
      <span text="">Game Coins: {{userInfo.balance}}</span>
      <img src="@/assets/add_coins.png" class="img-coins"/>
    </div>
    <div
        v-for="(order, index) in orders"
        :key="index"
        class="order-item"
        @click="OnGameItemClick(order.id)"
    >
      <img :src="order.logo" class="order-background">
      <div class="order-content">
        <div class="item-name">
          {{ order.english }}
        </div>
        <div class="item-enter">
          {{ "ENTER THE GAME  >>   " }}
        </div>
      </div>
    </div>
    <div ref="loadMore" class="load-more">{{ hasNext == 1 ? 'Load more...' : 'No more data' }}</div>
    <FloatingButton ref="floatingButton" class="floating-btn"/>
    <SystemPoPup   v-if="isShowPP"   @close="isShowPP = false">
      <template v-slot:body>
        <img :src="ppInfo.img" class="pp-img">
      </template>
    </SystemPoPup>
  </div>
</template>

<script>
import request from "@/utils/request";
import userService from "@/utils/userService";
import FloatingButton from "@/components/FloatingButton";
import SystemPoPup from "@/components/SystemPoPup";
import {event} from "vue-gtag";
export default {
  components: { FloatingButton,SystemPoPup},
  data() {
    return {
      orders: [],
      currentPage: 1,
      loading: false,
      hasNext: 1,
      userInfo:null,
      isShowPP:false,
      ppInfo:{
        img:""
      }
    };
  },
  methods: {
    async fetchOrders() {
      if (this.loading) return;

      this.loading = true;
      try {
        const response = await request({
          url: "/api/game/list",
          method: "post",
          data: {
            page: this.currentPage,
          },
        });
        const reversedOrders = response.items;
        this.orders = [...this.orders, ...reversedOrders];
        this.currentPage++;
        this.hasNext = response.next;
      } catch (error) {
        console.error('Error fetching orders:', error);
      } finally {
        this.loading = false;
      }
    },
   async OnGameItemClick(id) {
      try {
        this.$router.push({path:'/gamePlay',
          query: {id:id }})
       await request({
          url: "/api/game/click",
          method: "post",
          data: {
            game_id: id,
          },
        });
        event('click_play_game', { method: 'Click',value:id })
      } catch (error) {
        console.error('Error fetching orders:', error);
      }

    },

    recharge() {
      this.$router.push(`/payPage`);
    },
    setupIntersectionObserver() {
      const options = {
        root: null,
        rootMargin: '0px',
        threshold: 1.0,
      };
      const observer = new IntersectionObserver(this.loadMoreEntries, options);
      observer.observe(this.$refs.loadMore);
    },
    loadMoreEntries(entries) {
      const [entry] = entries;
      if (entry.isIntersecting) {
        this.fetchOrders();
      }
    },

  },

  mounted() {
    console.log("=====mounted")

    userService.getPoPup((error, response) => {

      if (error) {
        console.log("==========login error", error);
      } else if (response && response.activity_img) {
        this.isShowPP=true;
        this.ppInfo.img=response.activity_img
      }else {
        this.isShowPP=false;
        this.ppInfo.img=""
      }
      userService.updateUserInfo((error, response) => {
        this.userInfo = response;
      });
    })
    this.currentPage=1;
    this.fetchOrders();
    this.setupIntersectionObserver();
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  },
};
</script>

<style scoped>
.container {
  width: 100%;
  height: 100vh;
  background-image: url('@/assets/game_list_bg.png');
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #FFFFFF;
}


.img-bg {
  height: 300px;
}

.order-item {
  position: relative;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  margin: 10px 30px;
  /* 这里设置高度比例，假设图片比例是16:9 */
  padding-bottom: 56.25%;

}

.order-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.order-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: white;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7);
}

.item-name {
  width: 100%;
  height: 80px;
  font-family: PingFang SC;
  font-weight: 800;
  font-size: 38px;
  color: #FFB400;
  line-height: 10px;
  text-shadow: 0px 3px 0px #7A0000;
  text-stroke: 1px #D20A0A;
  -webkit-text-stroke: 1px #D20A0A;
  background: rgba(0, 0, 0, 0.55);
  text-align: center;
  line-height: 80px;
}

.item-enter {
  font-size: 28px;
  color: #FFFFFF;
  text-align: right;
  height: 60px;
  line-height: 60px;
  width: 100%;
  background: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
  white-space: pre-wrap;
}

.load-more {
  text-align: center;
  padding: 10px;
  color: white;
}

.img-coins {
  margin-left: 10px;
  width: 50px;
  height: 50px;
}

.game-coins {
  display: flex;
  justify-content: center;
  align-items: center;
}

.game-coins span {
  font-size: 30px;
  font-weight: bold;
  text-shadow: 0px 5px 0px rgb(114, 40, 254);
  /*文字的颜色*/
  color: #fff;
}
.pp-img{
  width: 100%;
  border-radius: 10px;

}


</style>
