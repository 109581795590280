<template>
  <div class="order-list">
    <account-header>
      <template v-slot:title>
        Order Details
      </template>
    </account-header>
    <div
        v-for="(order, index) in orders"
        :key="index"
        class="order-item"
    >
      <div class="order-item-text">
        Order ID: {{ order.orderNumber }}
      </div>
      <div class="order-item-text">
        Order Time: {{ order.createdView }}
      </div>
      <div class="order-item-text">
        Order Amount: {{ order.payMoney }}
      </div>
      <div class="order-item-text">
        Game Coins: {{ order.balance }}
      </div>
      <div class="order-item-text2">
        Payment Results: {{ order.statusView }}
      </div>
      <div v-if="order.timeView" class="order-item-text2">
        {{ order.timeView }}
        <!--        <strong>{{ order.paymentResults === 'Failed' ? 'Failed Time:' : 'Success Time:' }} </strong> {{ order.time }}-->
      </div>
    </div>
    <div ref="loadMore" class="load-more">{{ hasNext == 1 ? 'Load more...' : 'No more data' }}</div>
  </div>
</template>

<script>
import request from "@/utils/request";
import AccountHeader from "@/components/AccountHeader";

export default {
  components: {AccountHeader},
  data() {
    return {
      orders: [],
      currentPage: 1,
      loading: false,
      hasNext: 1,
    };
  },
  methods: {
    async fetchOrders() {
      if (this.loading) return;

      this.loading = true;
      try {
        const response = await request({
          url: "/api/order/list",
          method: "post",
          data: {
            page: this.currentPage,
            // pageSize: this.pageSize,
          },
        });
        console.log("=====", response.items)
        this.orders = [...this.orders, ...response.items];
        this.currentPage++;
        this.hasNext = response.next
      } catch (error) {
        console.error('Error fetching orders:', error);
      } finally {
        this.loading = false;
      }
    },
    setupIntersectionObserver() {
      const options = {
        root: null,
        rootMargin: '0px',
        threshold: 1.0,
      };
      const observer = new IntersectionObserver(this.loadMoreEntries, options);
      observer.observe(this.$refs.loadMore);
    },
    loadMoreEntries(entries) {
      const [entry] = entries;
      if (entry.isIntersecting) {
        this.fetchOrders();
      }
    },
  },
  mounted() {
    this.fetchOrders();
    this.setupIntersectionObserver();
  },
};
</script>

<style scoped>
.order-list {
  padding: 30px;
  background-color: #121212;
  position: fixed;
  overflow-y: auto; /* 使内容超过屏幕时可以滚动 */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.order-item {
  background: #282828;
  padding: 30px;
  margin-bottom: 20px;
  border-radius: 10px;
  color: white;
  font-size: 22px;
  line-height: 30px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.order-item > div {
  margin-bottom: 5px;
}

.load-more {
  text-align: center;
  padding: 10px;
  color: white;
}

.order-item-text {
  font-family: PingFang SC;
  font-weight: 400;
  font-size: 22px;
  color: #D7D7D7;
  line-height: 30px;
  text-stroke: 1px #333333;
  -webkit-text-stroke: 1px #333333;
}
.order-item-text2 {
  font-family: PingFang SC;
  font-weight: bold;
  font-size: 22px;
  color: #D7D7D7;
  line-height: 30px;
  text-stroke: 1px #333333;
  -webkit-text-stroke: 1px #333333;
}
</style>
