<template>
  <account-header  @onClickBack="$router.go(-1)"></account-header>
  <div>
    <h2>1. General Introduction, definitions, and stipulations</h2>
    <p>
      1.1 An “Account Holder” is an individual having a contractual relationship with YOMIGAME by creating an YOMIGAME Account on the Website.
    </p>
    <p>
      1.2 A “YOMIGAME Account” is an account held by an Account Holder, for bona fide transactions, with a strict aim to establish a normal commercial relationship with YOMIGAME and with the strict purpose of conducting betting and other gaming and gambling transactions.
    </p>
    <p>
      1.3 The “Contract” is the contractual relationship between YOMIGAME and an Account Holder and shall be constituted under and governed by the YOMIGAME Rules, i.e. the “Terms and Conditions”.
    </p>
    <p>
      1.4 The “Website” is the internet gateway accessible through the internet address iYOMIGAME.com where all current and relevant information regarding YOMIGAME operations is published, and through which the Services are provided to the Account Holders.
    </p>
    <p>
      1.5 The “Services” are the gaming and betting offers provided by YOMIGAME to the Account Holder through the Website.
    </p>

    <h2>2. Policy of site use</h2>
    <p>
      2.1 YOMIGAME does not bear any responsibility for any losses or damages claimed as resulting from the use of this site or from its content. This provision equally applies to the use or misuse of the content of the site by any person, inability to access the site or use it, to delay in functioning or transmission of data, failures in communication lines, any errors, misprints, or omissions in the content of the site.
    </p>
    <p>
      2.2 Loading photos and video from the website or its sub-sites are permitted only for private use. Publication, transmission, or reproduction of this data for any other purpose is strictly prohibited.
    </p>
    <p>
      2.3 YOMIGAME monitors traffic of its website actively and reserves the right to block access in cases of suspected automated betting (bots).
    </p>

    <h2>3. Privacy of User’s data</h2>
    <p>
      3.1 The User is responsible for maintaining the confidentiality of any information related to their account and shall take all measures to prevent the use of personal accounts by unauthorized persons. Any operations confirmed by the Mobile phone number and password of the account holder shall be valid and have legal force.
    </p>
    <p>
      3.2 If the User suspects that his Mobile phone number and password are known to a third person, at any time they have the right to send a request to YOMIGAME to block the account temporarily for all circumstances to be made clear.
    </p>
    <p>
      3.3 To ensure the security of personal data of Users, YOMIGAME communicates with Users via the Mobile phone number indicated by the User during registration.
    </p>
    <p>
      3.4 The User is responsible for maintaining the confidentiality of any information related to their Mobile phone number and shall take all measures to prevent the use of Mobile phone numbers by third parties. YOMIGAME is not responsible for any loss or damage resulting from the connection between the bookmaker office and the User using a registered Mobile phone number.
    </p>

    <h2>4. Neutral field</h2>
    <p>
      4.1 In cases when the match is transferred to a neutral field (NF) rates persist. They are refundable in cases when the match is transferred to the opponent’s field. In the case of the play when the teams are from the same city and are transferred to another field, bets are valid and are not refundable. In international matches, the refund of bets is made only when the match transfers to the field of an opponent state.
    </p>

    <h2>5. Registration</h2>
    <p>
      5.1 Only registered users can participate in the bonus-programs of the company. To register, fill in the registration form by providing your first name, last name, and Mobile phone number. After receiving a verification SMS, follow the instructions to verify your account to be able to make deposits and place bets.
    </p>
    <p>
      5.2 Registration and betting is allowed exclusively to people over 18 years old (or the allowed age range in the given country from which the user has opened a deposit and made bets). Users have complete responsibility as to dealing with the legality of Internet gambling in the region where they live and/or for providing the appropriate authorities of their country with information on winnings. The Company reserves the right to require proof of age and block the User’s account before receiving the appropriate documentation.
    </p>
    <p>
      5.3 All information provided during the registration must be accurate and complete. In particular, when using credit or debit cards, the cardholder’s name and surname must match the name and surname indicated in the registration form, otherwise, the account will be blocked. All bets made before locking the account are recognized as valid.
    </p>
    <p>
      5.4 The Company reserves the right to block the accounts of Users who have reported false data, as well as to deny them the payment of any winnings. At the request of the company, the User must present an official document with a photograph, identifying his identity (a passport copy), and also proof of authenticity of the indicated address data and telephone.
    </p>
    <p>
      5.5 Each User can have only one account. Registered Users cannot re-register as a new client with a new name or a new Mobile phone number. In case of violation of this rule, the company has the right to void all bets made by the User.
    </p>
    <p>
      5.6 The User has no right to permit any third party to use their game account.
    </p>
    <p>
      5.7 Please note that you should not send us the details about your credit card account or other sensitive financial information via an unencrypted e-mail.
    </p>
    <p>
      5.8 YOMIGAME allows all its Users to choose their own combination of passwords. Users should keep such information secret. If your password is entered correctly while accessing the site, all bets remain in force and cannot be canceled or changed by the User. If you suspect that someone other than you knows your password, change it immediately on our website. If you have forgotten the password or a part of it, please click forgot password when you log-in.
    </p>
    <p>
      5.9 Users who place their bets in YOMIGAME via a cell phone should remember that YOMIGAME is not responsible for any loss of data in the mobile phone of the client, and is not responsible for any commission of mobile and internet operators. By registering, the client confirms that he accepts and agrees to these Terms.
    </p>

    <h2>6. Deposits</h2>
    <p>
      6.1 Available methods of payments are determined by the country and the currency selected during registration. YOMIGAME reserves the right to change these terms and details.
    </p>
    <p>
      6.2 When conducting any financial transactions it is necessary that the name of the owner of the debit/credit card or bank account exactly matches the name of the owner of the appropriate account of YOMIGAME. Otherwise, YOMIGAME reserves the right to cancel all transactions and make a return on all bets made while using someone else’s account or credit/debit card.
    </p>

    <h2>7. Entry of money on account</h2>
    <p>
      7.1 If any funds have been transferred to the User erroneously, the User is obliged to notify the company immediately. Any winnings of the client arising from such an error shall be considered invalid, and such bets are refundable, regardless of the delay between the origin of the error and the time it was seen.
    </p>
    <p>
      7.2 If the deposits to the account were made for any other purpose than bets, poker, casino, and financial betting, the company (particularly in case of suspected fraudulent money turnover) reserves the right to cancel a deposit and collect from the User all costs incurred as a result of processing the deposit.
    </p>
    <p>
      7.3 If the User’s deposit exceeds the bet’s amount, at the client’s request for withdrawal YOMIGAME reserves the right to charge the User all costs incurred as a result of processing deposits and withdrawals.
    </p>

    <h2>8. Financial Limitations</h2>
    <p>
      8.1 The maximum winning of one ticket is 100,000,000 NGN. The maximum coefficient of a bet is 1000. In case it exceeds 1000, the winning is calculated with a 1000 coefficient.
    </p>
    <p>
      8.2 The minimum single bet on any event is 100 NGN. The minimum bet in the express bet is 100 NGN.
    </p>
    <p>
      8.3 The maximum coefficient of express bets is 1000. Express bets, in which the coefficient of winnings exceeds the maximum, are calculated with a coefficient of 1000. In these cases, the sum of winnings should not exceed the maximum payment for a single bet. The maximum coefficient of winnings for each version of the system is 1000. Variants of systems on which the coefficient of winnings exceeds the maximum is calculated with coefficient 1000.
    </p>
    <p>
      8.4 The maximum amount of the bet on the event depends on the sport and the events, and is defined by the bookmaker network specifically for each event and each type of bet, and is subject to change without prior written notice. YOMIGAME reserves the right to limit the maximum bet on individual events, as well as the introduction and removal of specific restrictions on the accounts of individual Users without notice or explanation of reasons.
    </p>
    <p>
      8.5 All financial limits apply to any individual customer or/and syndicates, group of players acting as a collective unit, who have placed two or more bets containing the same match selections, including where placed in a series of bets at a different range of odds, markets over several days using various players’ accounts and/or across different betting channels. Should we identify that two or more bets have been placed this way, all those bets will be considered as void and only the first betting slip submitted will be considered as a legitimate bet.
    </p>

    <h2>9. Payments / Withdrawals</h2>
    <p>
      9.1 Payments are processed in a time not exceeding 72 hours, starting from the moment that the request was made. Before the first payment will be made to the User by electronic payment methods. Remember that forgery is severely punished by law, and in cases of suspicion of placing a counterfeit or an edited copy of the documents by electronic methods, YOMIGAME reserves the right to send such documents to the appropriate regulatory authorities.
    </p>
    <p>
      9.2 Before making the payment, the employees of the company will verify the correspondence of the name, surname, father’s name, birth dates of the client, and also other data. If differences are found between the actual data and the data provided by the client, YOMIGAME reserves the right to make a refund for all bets by the User and refuse to pay out winnings to the User unless they prove the identity and accuracy of entered data.
    </p>
    <p>
      9.3 If it turns out that the User has opened several accounts in the company, YOMIGAME reserves the right to refuse to pay out for these accounts (except the User’s assets legitimately transferred to the account of YOMIGAME after he pays a fine in the measure of 10% of the total amount of deposits).
    </p>
    <p>
      9.4 Group and family members should regulate personal relationships with each other – payments are made ONLY in the name of the owner of the appropriate account.
    </p>
    <p>
      9.5 The User agrees to provide YOMIGAME with information about his bank account from which the bets will be made, in particular, to transfer his winnings.
    </p>
    <p>
      9.6 YOMIGAME is not responsible for changes in the number of payments related to fluctuations in currency (rate of exchange).
    </p>
    <p>
      9.7 The Company reserves the right of funds’ withdrawal using a priority for itself method of payment for winning players (including credit/debit card or to the player’s bank account).
    </p>
  </div>

</template>

<script>
import AccountHeader from "@/components/AccountHeader";

export default {
  name: 'UserAgreement',
  components: {
    AccountHeader,
  },
}
</script>

<style scoped>
/* General container styling */
div {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #333;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  max-width: 800px;

  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* Heading styling */
h2 {
  font-size: 0.7em;

  margin-top: 15px;

  padding-bottom: 5px;
}

/* Paragraph styling */
p {
  font-size: 0.4em;
  margin: 8px 0;
}

/* List styling */
ul {
  padding-left: 20px;
  margin: 10px 0;
}

ul li {
  list-style-type: disc;
  margin: 5px 0;
}

/* Link styling */
a {
  color: #007BFF;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

/* Button styling */
button {
  background-color: #007BFF;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  margin-top: 20px;
}

button:hover {
  background-color: #0056b3;
}
</style>

